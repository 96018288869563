<template>
  <section class="verification-modal">
    <StDrawerHeader v-if="isMobile" @close="closeModal" />
    <div ref="verification" class="verification" />
  </section>
</template>

<script setup lang="ts">
import { useSumSubVerification } from '../../composable/useSumSubVerification'

const verification = ref<HTMLElement>()
const { startVerification } = useSumSubVerification()

const router = useRouter()
function closeModal() {
  router.replace({ query: {} })
}

const { isMobile } = usePlatform()

onMounted(() => {
  startVerification(verification)
})
</script>

<style scoped>
.verification-modal {
  overflow: hidden;
  width: 600px;
  min-height: 260px;
  padding: var(--spacing-200);

  @media screen and (width <= 1024px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    width: 100%;
    padding: var(--spacing-100);
  }
}

.verification {
  overflow: hidden;
  overflow-y: auto;

  max-height: 80vh;
  margin-right: calc(var(--spacing-400) * -1);
  padding-right: var(--spacing-400);

  @media screen and (width <= 1024px) {
    flex-grow: 1;
    max-height: 90vh;
    margin: 0;
    padding: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
